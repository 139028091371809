export default {
    plans: {
        en: {
            _defaultDomain: 'https://builderall.com/',
            home: '{domain}home',
            plans: 'https://checkout.builderall.com/plans',
            ourTools: '{domain}our-tools',
            successStories: '{domain}new-success-stories',
            youtube: 'https://www.youtube.com/@BuilderallUSA',
            facebook: 'https://www.facebook.com/groups/builderall/?ref=share&mibextid=lURqYx',
            instagram: 'https://www.instagram.com/builderallofficial_/',
            getSupport: '{domain}support',
            login: 'https://office.builderall.com/us/office',
            getStart: 'https://checkout.builderall.com/plans',
            about: '{domain}about-us',
            affiliate: '{domain}affiliates',
            allTools: '{domain}/our-tools',
            tools: {
                website: 'https://tools.builderall.com/builderall-builder',
                emailMarketing: 'https://tools.builderall.com/mailingboss',
                courseCreator: 'https://tools.builderall.com/e-learning',
                chatbots: 'https://tools.builderall.com/messenger-chatbot',
                funnelBuilder: 'https://tools.builderall.com/funnel-builder',
                allTools: 'https://www.builderall.com/our-tools'
            },
            solutions: {
                healthWellness: 'https://www.builderall.com/solutions-health',
                realEstate: 'https://www.builderall.com/solutions-real-estate',
                freelancers: 'https://www.builderall.com/solutions-freelancer',
                agencies: 'https://www.builderall.com/solutions-agency',
                startups: 'https://www.builderall.com/solutions-startups',
                courseCreator: 'https://www.builderall.com/solutions-course-creator'
            },
            blog: '{domain}blog',
            elements: '{domain}elements',
            partners: '{domain}partners',
            legalInformation: '{domain}legal-information',
            termsOfUse: '{domain}terms-of-use',
            privacyPolice: 'https://www.iubenda.com/privacy-policy/77224872',
            cookiePolicy: 'https://www.iubenda.com/privacy-policy/77224872/cookie-policy'
        },
        pt: {
            _defaultDomain: 'https://br.builderall.com/',
            home: '{domain}home',
            plans: 'https://checkout.builderall.com/plans',
            ourTools: '{domain}nossas-ferramentas',
            successStories: '{domain}historias-sucesso',
            youtube: 'https://www.youtube.com/channel/UCCDeKQkMbxm18nYpQbqBlGQ',
            facebook: 'https://www.facebook.com/builderall.brasil.official/',
            instagram: 'https://www.instagram.com/builderallbroficial/',
            getSupport: '{domain}pagina-suporte',
            login: 'https://office.builderall.com/br/office',
            getStart: 'https://checkout.builderall.com/plans',
            about: '{domain}sobre-nos',
            affiliate: 'https://afiliados.builderall.com/',
            allTools: '{domain}nossas-ferramentas',
            tools: {
                website: 'https://ferramentas-br.builderall.com/builderall-builder',
                emailMarketing: 'https://ferramentas-br.builderall.com/mailingboss',
                courseCreator: 'https://ferramentas-br.builderall.com/e-learning',
                chatbots: 'https://ferramentas-br.builderall.com/chatbuilder',
                funnelBuilder: 'https://ferramentas-br.builderall.com/funnel-builder',
                allTools: 'https://www.br.builderall.com/nossas-ferramentas'
            },
            solutions: {
                healthWellness: 'https://br.builderall.com/saude--bem-estar',
                realEstate: 'https://br.builderall.com/imobiliaria',
                freelancers: 'https://br.builderall.com/freelancers',
                agencies: 'https://br.builderall.com/agencias',
                startups: 'https://br.builderall.com/startups',
                courseCreator: 'https://br.builderall.com/cursos-online'
            },
            blog: '{domain}blog',
            elements: '{domain}elementos',
            partners: 'https://8303a79b1e19a194f187-cerificados-builderall.cheetah.builderall.com/feed',
            legalInformation: '{domain}informacoes-legais',
            termsOfUse: '{domain}termos-uso',
            privacyPolice: 'https://www.iubenda.com/privacy-policy/20270869',
            cookiePolicy: 'https://www.iubenda.com/privacy-policy/20270869/cookie-policy'
        },
        es: {
            _defaultDomain: 'https://es.builderall.com/',
            home: '{domain}home',
            plans: 'https://checkout.builderall.com/plans',
            ourTools: '{domain}nuestras-apps',
            successStories: '{domain}historias-exito',
            youtube: 'https://www.youtube.com/channel/UCNs5F6yOZkKvI-ZykvL0m9w',
            facebook: 'https://www.facebook.com/builderall.spanish/',
            instagram: 'https://www.instagram.com/builderall.es/',
            getSupport: '{domain}soporte',
            login: 'https://office.builderall.com/es/office',
            getStart: 'https://checkout.builderall.com/plans',
            about: '{domain}nosotros',
            affiliate: 'https://afiliados-es.builderall.com/',
            allTools: '{domain}nuestras-apps',
            tools: {
                website: 'https://herramientas-es.builderall.com/builderall-builder',
                emailMarketing: 'https://herramientas-es.builderall.com/mailingboss',
                courseCreator: 'https://herramientas-es.builderall.com/e-learning',
                chatbots: 'https://herramientas-es.builderall.com/chatbot-messenger',
                funnelBuilder: 'https://herramientas-es.builderall.com/funnel-builder',
                allTools: 'https://es.builderall.com/nuestras-apps'
            },
            solutions: {
                healthWellness: 'https://es.builderall.com/soluciones---salud',
                realEstate: 'https://es.builderall.com/soluciones---bienes-raices',
                freelancers: 'https://es.builderall.com/soluciones---freelancer',
                agencies: 'https://es.builderall.com/soluciones---agencia',
                startups: 'https://es.builderall.com/soluciones---startups',
                courseCreator: 'https://es.builderall.com/soluciones---creador-de-cursos'
            },
            blog: '{domain}blog',
            elements: '{domain}elementos',
            partners: 'https://partners.builderall.com/',
            legalInformation: '{domain}informacion-legal',
            termsOfUse: '{domain}terminos-de-uso',
            privacyPolice: 'https://www.iubenda.com/privacy-policy/22033321',
            cookiePolicy: 'https://www.iubenda.com/privacy-policy/22033321/cookie-policy'
        },
        it: {
            _defaultDomain: 'https://it.builderall.com/',
            home: '{domain}',
            plans: 'https://checkout.builderall.com/plans',
            ourTools: '{domain}i-nostri-strumenti',
            successStories: '{domain}storie-di-successo',
            youtube: 'https://www.youtube.com/@BuilderallUSA',
            facebook: 'https://www.facebook.com/groups/builderall/?ref=share&mibextid=lURqYx',
            instagram: 'https://www.instagram.com/builderallofficial_/',
            getSupport: '{domain}supporto',
            login: 'https://office.builderall.com/it/office',
            getStart: 'https://checkout.builderall.com/plans',
            about: '{domain}chi-siamo',
            affiliate: 'https://italian-affiliati-eu-copy.cheetah.builderall.com/affiliati',
            allTools: '{domain}i-nostri-strumenti',
            tools: {
                website: 'https://strumenti-it.builderall.com/builderall-builder',
                emailMarketing: 'https://strumenti-it.builderall.com/mailingboss',
                courseCreator: 'https://strumenti-it.builderall.com/e-learning',
                chatbots: 'https://strumenti-it.builderall.com/chatbot-messenger',
                funnelBuilder: 'https://strumenti-it.builderall.com/costruttore-di-funnel',
                allTools: 'https://it.builderall.com/i-nostri-strumenti'
            },
            solutions: {
                healthWellness: 'https://it.builderall.com/soluzioni-salute',
                realEstate: 'https://it.builderall.com/soluzioni-immobiliare',
                freelancers: 'https://it.builderall.com/soluzioni-freelancer',
                agencies: 'https://it.builderall.com/soluzioni-agenzia',
                startups: 'https://it.builderall.com/soluzioni-startup',
                courseCreator: 'https://it.builderall.com/soluzioni-creatore-di-corsi'
            },
            blog: '{domain}blog',
            elements: '{domain}elementi',
            partners: 'https://partners-it.builderall.com/',
            legalInformation: '{domain}informazioni-legali',
            termsOfUse: '{domain}termini-di-uso',
            privacyPolice: 'https://www.iubenda.com/privacy-policy/89274845',
            cookiePolicy: 'https://www.iubenda.com/privacy-policy/89274845/cookie-policy'
        },
        fr: {
            _defaultDomain: 'https://fr.builderall.com/',
            home: '{domain}',
            plans: 'https://checkout.builderall.com/plans',
            ourTools: '{domain}our-tools',
            successStories: '{domain}histoires-a-succes',
            youtube: 'https://www.youtube.com/@BuilderallUSA',
            facebook: 'https://www.facebook.com/groups/builderall/?ref=share&mibextid=lURqYx',
            instagram: 'https://www.instagram.com/builderallofficial_/',
            getSupport: '{domain}page-support',
            login: 'https://office.builderall.com/fr/office',
            getStart: 'https://checkout.builderall.com/plans',
            about: '{domain}about-us',
            affiliate: 'https://affiliates.builderall.com/',
            allTools: '{domain}our-tools',
            tools: {
                website: 'https://outils-fr.builderall.com/builderall-builder',
                emailMarketing: 'https://outils-fr.builderall.com/mailingboss',
                courseCreator: 'https://outils-fr.builderall.com/e-learning',
                chatbots: 'https://outils-fr.builderall.com/chatbot-messenger',
                funnelBuilder: 'https://outils-fr.builderall.com/funnel-builder',
                allTools: 'https://fr.builderall.com/our-tools'
            },
            solutions: {
                healthWellness: 'https://fr.builderall.com/solutions---sante',
                realEstate: 'https://fr.builderall.com/solutions---agence-immobiliere',
                freelancers: 'https://fr.builderall.com/solutions---freelancer',
                agencies: 'https://fr.builderall.com/solutions---agence',
                startups: 'https://fr.builderall.com/solutions---startups',
                courseCreator: 'https://fr.builderall.com/solutions---createur-de-formation'
            },
            blog: '{domain}blog',
            elements: '{domain}elements',
            partners: 'https://partners.builderall.com/',
            legalInformation: '{domain}informations-legales',
            termsOfUse: '{domain}conditions-d-utilisation',
            privacyPolice: 'https://www.iubenda.com/privacy-policy/29494930',
            cookiePolicy: 'https://www.iubenda.com/privacy-policy/29494930/cookie-policy'
        },
        de: {
            _defaultDomain: 'https://www.builderall.de/',
            home: '{domain}',
            plans: 'https://checkout.builderall.com/plans',
            ourTools: '{domain}unsere-tools',
            successStories: '{domain}erfolgs-stories',
            youtube: 'https://www.youtube.com/@builderall.de_offical',
            facebook: 'https://www.facebook.com/builderallde',
            instagram: 'https://www.instagram.com/builderallofficial_/',
            getSupport: '{domain}support-seite',
            login: 'https://office.builderall.com/de/office',
            getStart: 'https://checkout.builderall.com/plans',
            about: '{domain}ueber-uns',
            affiliate: 'https://affiliates.builderall.de/',
            allTools: '{domain}unsere-tools',
            tools: {
                website: 'https://tools.builderall.de/builderall-builder',
                emailMarketing: 'https://tools.builderall.de/mailingboss',
                courseCreator: 'https://tools.builderall.de/e-learning',
                chatbots: 'https://tools.builderall.de/messenger-chatbot',
                funnelBuilder: 'https://tools.builderall.de/funnel-builder',
                allTools: 'https://builderall.de/unsere-tools'
            },
            solutions: {
                healthWellness: 'https://builderall.de/solutions-health',
                realEstate: 'https://builderall.de/solutions-real-estate',
                freelancers: 'https://builderall.de/solutions-freelancer',
                agencies: 'https://builderall.de/solutions-agency',
                startups: 'https://builderall.de/solutions-startups',
                courseCreator: 'https://builderall.de/solutions-course-creator'
            },
            blog: '{domain}blog',
            elements: '{domain}elemente',
            partners: 'https://partners.builderall.com/',
            legalInformation: '{domain}impressum',
            termsOfUse: '{domain}nutzungsbedingungen',
            privacyPolice: 'https://www.iubenda.com/privacy-policy/24235906',
            cookiePolicy: 'https://www.iubenda.com/privacy-policy/24235906/cookie-policy'
        }
    }
}